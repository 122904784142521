import * as React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Box, Container, Typography } from '@mui/material';

const NotFoundPage: React.FC = () => (
  <Layout gradientHeight={[1000]}>
    <SEO title="404: Not found" />
    <Box pt={16} />
    <Container>
      <Typography variant="h1" align="center">
        404
      </Typography>
      <br />
      <Typography variant="body1" align="center">
        You just hit a route that doesn&#39;t exist... the sadness.
      </Typography>
    </Container>
    <Box pt={16} />
  </Layout>
);

export default NotFoundPage;
